import axios from '../../plugins/axios';

export default {
    namespaced: true,

    state: {
        entities: []
    },

    mutations: {
        SET_ENTITIES(state, payload) {
            state.entities = payload;
        }
    },

    actions: {
        async fetch({ commit }) {
            const { data } = await axios.get('/admin/genders');
            commit('SET_ENTITIES', data);
            return data;
        }
    },

    getters: {}
}
