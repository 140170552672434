import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth';
import files from './modules/files';

import genders from './modules/genders';
import sizes from './modules/sizes';
import fabrics from './modules/fabrics';
import categories from './modules/categories';
import categoryProducts from './modules/categoryProducts';
import products from './modules/products';
import productSizes from './modules/productSizes';
import productFabrics from './modules/productFabrics';
import orders from './modules/orders';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        auth,
        files,
        genders,
        sizes,
        fabrics,
        categories,
        categoryProducts,
        products,
        productSizes,
        productFabrics,
        orders,
    }
})
