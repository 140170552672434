<template>
    <v-app>
        <v-app-bar
            app
            color="primary"
            dark
            absolute
            elevate-on-scroll
        >
            <v-app-bar-nav-icon 
                v-if="user.id"
                @click.stop="drawer = !drawer" />
        </v-app-bar>

        <v-main>
            <v-navigation-drawer
                v-model="drawer"
                absolute
                temporary
            >
                <v-list
                    nav
                    dense
                >
                    <v-list-item-group>
                        <v-list-item @click="$router.push({ name: 'orders-list' })">
                            <v-list-item-title>Заказы</v-list-item-title>
                        </v-list-item>
                        <v-divider />
                        <v-list-item @click="$router.push({ name: 'categories-list' })">
                            <v-list-item-title>Категории</v-list-item-title>
                        </v-list-item>
                        <v-divider />
                        <v-list-item @click="$router.push({ name: 'products-list' })">
                            <v-list-item-title>Товары</v-list-item-title>
                        </v-list-item>
                        <v-divider />
                        <v-list-item @click="$router.push({ name: 'sizes-list' })">
                            <v-list-item-title>Размеры</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="$router.push({ name: 'fabrics-list' })">
                            <v-list-item-title>Ткани</v-list-item-title>
                        </v-list-item>
                        <v-divider />
                        <v-list-item @click="logout">
                            <v-list-item-title>Выйти</v-list-item-title>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-navigation-drawer>
            <div class="ma-4">
                <router-view />
            </div>
        </v-main>
    </v-app>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';

    export default {
        name: 'TIDwear',
        metaInfo: {
            title: 'TIDwear',
            titleTemplate: '%s | TIDwear'
        },
        data: () => ({
            drawer: null,
        }),
        computed: {
            ...mapState('auth', ['user']),
        },
        async mounted() {
            if(!this.user.id) {
                await store.dispatch('auth/fetch');
                if (!this.user.id) {
                    await this.$router.push({ name: 'user-authorization' });
                }
            }
        },
        methods: {
            async logout() {
                await store.dispatch('auth/logout');
                await this.$router.push({ name: 'user-authorization' });
            }
        }
    };
</script>