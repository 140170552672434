import router from '@/router';
import store from '@/store';
import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.VUE_APP_API_URL
})

instance.interceptors.request.use(function (config) {
    const token = localStorage.getItem('tidwear-admin-token');
    if (typeof token === 'string') {
        config.headers['Authorization'] = token.replace(/"/g, '');
    }
    return config;
}, function (error) {
    return Promise.reject(error);
})

instance.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    const statusCode = error.response ? error.response.status : null;
    switch(statusCode) {
        case 401:
            if (error.response.config.url !== '/common/auth/profile') {
                store.dispatch('auth/logout');
                router.push({ name: 'user-authorization' });
            }
            break;
        // case 500:
        //     router.push({ name: 'home' });
        //     break;
    }
    return Promise.reject(error);
})

export default instance;
