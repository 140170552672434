import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c(VAppBar,{attrs:{"app":"","color":"primary","dark":"","absolute":"","elevate-on-scroll":""}},[(_vm.user.id)?_c(VAppBarNavIcon,{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}):_vm._e()],1),_c(VMain,[_c(VNavigationDrawer,{attrs:{"absolute":"","temporary":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c(VList,{attrs:{"nav":"","dense":""}},[_c(VListItemGroup,[_c(VListItem,{on:{"click":function($event){return _vm.$router.push({ name: 'orders-list' })}}},[_c(VListItemTitle,[_vm._v("Заказы")])],1),_c(VDivider),_c(VListItem,{on:{"click":function($event){return _vm.$router.push({ name: 'categories-list' })}}},[_c(VListItemTitle,[_vm._v("Категории")])],1),_c(VDivider),_c(VListItem,{on:{"click":function($event){return _vm.$router.push({ name: 'products-list' })}}},[_c(VListItemTitle,[_vm._v("Товары")])],1),_c(VDivider),_c(VListItem,{on:{"click":function($event){return _vm.$router.push({ name: 'sizes-list' })}}},[_c(VListItemTitle,[_vm._v("Размеры")])],1),_c(VListItem,{on:{"click":function($event){return _vm.$router.push({ name: 'fabrics-list' })}}},[_c(VListItemTitle,[_vm._v("Ткани")])],1),_c(VDivider),_c(VListItem,{on:{"click":_vm.logout}},[_c(VListItemTitle,[_vm._v("Выйти")])],1)],1)],1)],1),_c('div',{staticClass:"ma-4"},[_c('router-view')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }