import axios from '../../plugins/axios';

export default {
    namespaced: true,

    state: {
        entities: [],
        entitiesCount: 0,
        entity: {}
    },

    mutations: {
        SET_ENTITIES(state, payload) {
            state.entities = payload;
        },
        SET_ENTITY(state, payload) {
            state.entity = payload;
        },
        CLEAR_ENTITY(state) {
            state.entity = {};
        },
    },

    actions: {
        async save({ commit, state }, { category_id }) {
            const { data } = await axios.post(`/admin/categories/${category_id}/products`, state.entity);
            return data;
        },
        async delete({ commit }, { category_id, id }) {
            await axios.delete(`/admin/categories/${category_id}/products/${ id }`);
        },
    },

    getters: {}
}
