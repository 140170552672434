import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
}

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        redirect: {
            name: 'orders-list'
        }
    }, {
        path: '/user/authorization',
        name: 'user-authorization',
        component: () => import('../views/user/Authorization.vue')
    }, {
        path: '/user/confirmation',
        name: 'user-confirmation',
        component: () => import('../views/user/Confirmation.vue')
    }, {
        path: '/sizes',
        name: 'sizes-list',
        component: () => import('../views/sizes/List.vue')
    }, {
        path: '/fabrics',
        name: 'fabrics-list',
        component: () => import('../views/fabrics/List.vue')
    }, {
        path: '/categories',
        name: 'categories-list',
        component: () => import('../views/categories/List.vue')
    }, {
        path: '/categories/:id/detail',
        name: 'categories-detail',
        component: () => import('../views/categories/Detail.vue')
    }, {
        path: '/categories/edit',
        name: 'categories-create',
        component: () => import('../views/categories/Edit.vue')
    }, {
        path: '/categories/:id/edit',
        name: 'categories-edit',
        component: () => import('../views/categories/Edit.vue')
    }, {
        path: '/categories/:id/products',
        name: 'categories-products',
        component: () => import('../views/categories/Products.vue')
    }, {
        path: '/products',
        name: 'products-list',
        component: () => import('../views/products/List.vue')
    }, {
        path: '/products/:id/detail',
        name: 'products-detail',
        component: () => import('../views/products/Detail.vue')
    }, {
        path: '/products/edit',
        name: 'products-create',
        component: () => import('../views/products/Edit.vue')
    }, {
        path: '/products/:id/edit',
        name: 'products-edit',
        component: () => import('../views/products/Edit.vue')
    }, {
        path: '/products/:id/sizes',
        name: 'products-sizes',
        component: () => import('../views/products/Sizes.vue')
    }, {
        path: '/products/:id/fabrics',
        name: 'products-fabrics',
        component: () => import('../views/products/Fabrics.vue')
    }, {
        path: '/products/:id/categories',
        name: 'products-categories',
        component: () => import('../views/products/Categories.vue')
    }, {
        path: '/orders',
        name: 'orders-list',
        component: () => import('../views/orders/List.vue')
    }, {
        path: '/orders/:id/detail',
        name: 'orders-detail',
        component: () => import('../views/orders/Detail.vue')
    },
]

const router = new VueRouter({
    routes,
    base: '/',   
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
});

export default router
