import axios from '../../plugins/axios';

export default {
    namespaced: true,

    state: {
        entities: [],
        entitiesCount: 0,
        entity: {}
    },

    mutations: {
        SET_ENTITIES(state, payload) {
            state.entities = payload;
        },
        SET_ENTITY(state, payload) {
            state.entity = payload;
        },
        CLEAR_ENTITY(state) {
            state.entity = {};
        },
    },

    actions: {
        async fetch({ commit }) {
            const { data } = await axios.get('/admin/fabrics');
            commit('SET_ENTITIES', data);
            return data;
        },
        async get({ commit }, { id }) {
            const { data } = await axios.get(`/admin/fabrics/${ id }`);
            commit('SET_ENTITY', data);
        },
        async save({ commit, state }) {
            let response = {};
            if(state.entity.id) {
                response = await axios.put(`/admin/fabrics/${ state.entity.id }`, state.entity);
            } else {
                response = await axios.post('/admin/fabrics', state.entity);
            }
            return response.data;
        },
        async delete({ commit }, { id }) {
            await axios.delete(`/admin/fabrics/${ id }`);
        },
    },

    getters: {}
}
